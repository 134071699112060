import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const AirConditions = ({ hasAirConditionRooms }) => {
    const { t } = useTranslation();

    return (
        <div className='inline-flex items-center'>
            <img
                className='snowflake_image w-[2rem]'
                src={require(
                    `assets/default/pages/Homepage/${hasAirConditionRooms?.image}`
                )}
                alt='snowflake'
            />
            <p className='tracking-[0.5px] text-[1.3rem] m-[unset] md:text-[1rem]'>
                {t(hasAirConditionRooms?.text)}
            </p>
            <img
                className='snowflake_image w-[2rem]'
                src={require(
                    `assets/default/pages/Homepage/${hasAirConditionRooms?.image}`
                )}
                alt='snowflake'
            />
        </div>
    );
};

AirConditions.propTypes = {
    hasAirConditionRooms: PropTypes.any,
};

export default AirConditions;
