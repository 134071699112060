import { useTranslation } from "react-i18next";
import config from "config/main-config.json";
import { twMerge } from "tailwind-merge";

export const Careers = () => {
    const { t } = useTranslation();
    const { careerText, careerButton, careerEmail } = config.careerPage;

    return (
        <section
            aria-label='careers'
            className='content-section text-center'>
            <div
                className={twMerge(
                    "bg-toronto-careers-image bg-cover bg-no-repeat bg-center",
                    "w-[100vw] h-[100vh] mt-[-10%]",
                    "mdl:h-[45vh] mdl:mt-[unset]"
                )}></div>
            <div className='row'>
                <div className='col-lg-6 mx-auto align-self-center pb-small'>
                    <h2 className='underline underline-offset-[20%] decoration-primary-red text-[3rem] font-black my-[2rem]'>
                        CAREERS
                    </h2>
                    <p className='content-sub'>{t(careerText)}</p>
                </div>
            </div>
            <div className='row'>
                <a
                    href={`mailto:${careerEmail}`}
                    className={twMerge(
                        "border border-solid border-primary-red text-primary-red",
                        "font-Raleway text-[18px] font-normal mx-[auto]",
                        "my-[1rem] py-[0.5rem] px-[1rem] uppercase",
                        "align-middle touch-manipulation hover:bg-primary-red",
                        "hover:text-black hover:outline-0 hover:no-underline"
                    )}>
                    {t(careerButton)}
                </a>
            </div>
        </section>
    );
};
