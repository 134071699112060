import { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import ResovaFooter from "components/Booking/ResovaFooter";

const ResovaIframe = ({ src }) => {
    const [iframeHeight, setIframeHeight] = useState("100px");

    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);

        return () => {
            window.removeEventListener("message", receiveMessage);
        };
    }, []);

    function receiveMessage(event) {
        let data;
        try {
            data = JSON.parse(event.data);
        } catch (error) {
            return;
        }

        if (data && data.readjust_height) {
            const newHeight = data.readjust_height;
            setIframeHeight(newHeight);
        }
    }

    return (
        <>
            <iframe
                id='resova-wrapper'
                src={src}
                title='External Voucher'
                height={iframeHeight}
                scrolling='no'
                className='w-[80%]'
                frameBorder='0'></iframe>
            <ResovaFooter />
        </>
    );
};

ResovaIframe.propTypes = {
    src: PropTypes.any,
};

export default ResovaIframe;
