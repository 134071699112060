const ResovaFooter = () => {
    return (
        <div id='resovaFoot'>
            <a href='https://resova.com/'>
                <span> Go Karting Booking Software BY </span>
                <img
                    alt='Resova footer'
                    src='https://d1ktc0bp273yrr.cloudfront.net/Universal/images/branding/resova_main_logos/resova_logo_main.svg'
                />
            </a>
        </div>
    );
};
export default ResovaFooter;
