import config from "config/main-config.json";
import ImageExtractor from "utils/Extractors/images";

/**
 * @export the enriched scenarios into the Scenarios.jsx component
 */

/**
 * @name mapDualStoreScenarios
 */
export let mapDualStoreScenarios = () => {
    return config.stores.map((store) => {
        const categories = [
            ...new Set(
                store.runtime.pages.rooms.flatMap((room) => {
                    if (room.filter_categories) {
                        return (
                            room.filter_categories &&
                            room.filter_categories.split(" / ")
                        );
                    } else {
                        return room.category && room.category.split(" / ");
                    }
                })
            ),
        ];

        return {
            name: store.name,
            title: store.runtime.pages.home.components.location.infos.title,
            address: store.runtime.pages.home.components.location.infos.address,
            filters: [
                {
                    id: "0",
                    value: "all",
                },
                ...categories.map((category, index) => ({
                    id: (index + 1).toString(),
                    value: category,
                })),
            ],
            roomsMeterText: store.runtime.pages.home.components.roomsMeterText,
            scenariosRedText:
                store.runtime.pages.home.components.scenariosRedText,
            outDoorActivities:
                store.runtime.pages.home.components.outdoorActivities,
            scenarios: store.runtime.pages.home.components.scenarios.map(
                (item) => {
                    const room = store.runtime.pages.rooms.find(
                        (roomItem) => roomItem.id === item.href
                    );
                    return {
                        ...item,
                        roomCategory: room?.filter_categories
                            ? room?.filter_categories
                            : room?.category,
                        images: {
                            desktop: ImageExtractor(
                                `scenarios/desktop/${item.images.jpeg}`
                            ),
                            mobile: ImageExtractor(
                                `scenarios/mobile/${item.images.jpeg}`
                            ),
                            svg: ImageExtractor(
                                `default/svgs/${item.images.svg}`
                            ),
                        },
                    };
                }
            ),
        };
    });
};
