/* eslint-disable sonarjs/no-nested-template-literals */
import { useTranslation } from "react-i18next";
import config from "config/main-config.json";

export const getImageAttributes = (isPortrait, type) => {
    let className =
        window.innerWidth >= 992
            ? "block w-100"
            : `block ${type !== "rooms" ? "" : "w-[100%]"} ${
                  isPortrait ? "" : `portrait-mode w-[100%]`
              }`;

    let id = isPortrait ? `${type !== "rooms" ? "carousel-images" : ""}` : "";

    return { className, id };
};

export const getCaptionsContainer = (item) => {
    const { t } = useTranslation();
    if (item.captions.type === "image") {
        return renderImageCaption(item.captions.image);
    } else if (item.captions.type === "text") {
        const text_captions = item.captions.text_cap;
        const captionElements = processTextCaptions(text_captions, t);
        return (
            <div className='relative w-[100%] min-h-[1px] px-[1rem] basis-0 grow-[1] max-w-[100%] !justify-center'>
                {captionElements}
            </div>
        );
    }
};

const renderImageCaption = (image) => {
    return (
        image && (
            <img
                alt={image}
                src={require(`assets/homeCarousel/${image}`)}
                className={`inline-flex max-w-[19%] xl:max-w-[25%] md:max-w-[40%] ${image.replace(
                    ".",
                    ""
                )}`}
                loading='lazy'
            />
        )
    );
};

const processTextCaptions = (text_captions, t) => {
    const text_captions_words = text_captions?.split(" ");

    const captionElements = [];
    let combinedWord = null;
    text_captions_words?.forEach((word, index) => {
        if (
            word.toUpperCase() === "THE" ||
            word.toUpperCase() === "GAME" ||
            word.toUpperCase() === "COMING"
        ) {
            combinedWord = word;
        } else {
            if (combinedWord !== null) {
                const combined = `${
                    captionElements.pop().props.children
                } ${combinedWord}`;
                captionElements.push(
                    renderCaptionElement(combined, combinedWord, t)
                );
                combinedWord = null;
            }
            captionElements.push(renderCaptionElement(word, index, t));
        }
    });

    return captionElements;
};

const renderCaptionElement = (word, key, t) => {
    const selectedLanguage = localStorage.getItem("language");

    return (
        <div
            key={key}
            id={word}
            className={selectedLanguage + "_" + word}>
            {t(word)}
        </div>
    );
};

export const hasCaptions = (item) => {
    return item.captions && item.captions.text && item.captions.text.length > 0;
};

export const getSwiperClassName = (
    type,
    styles,
    carouselData,
    currentSlide
) => {
    const hasCustomHeight = carouselData.some(
        (item, itemIndex) => item.hasCustomHeight && itemIndex === currentSlide
    );
    const { name } = config.stores[0];

    return `
        ${
            type !== "rooms"
                ? styles["home-page-carousel"]
                : styles["room-page-carousel"]
        }
        ${hasCustomHeight ? styles[`${name}_has_custom_height`] : ""}`;
};
