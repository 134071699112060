import i18next from "i18next";
import config from "config/main-config.json";
import { initReactI18next } from "react-i18next";

let Translator;

i18next
    .use(initReactI18next)
    .init({
        fallbackLng: config.supported_langs[0] ?? "en",
        debug: false,
        supportedLngs: config.supported_langs,
        react: {
            bindI18n: "languageChanged",
            bindI18nStore: "",
            transEmptyNodeValue: "",
            transSupportBasicHtmlNodes: true,
        },
        resources: {},
    })
    .then((t) => (Translator = t));

let lang = localStorage.getItem("language");

if (lang) {
    i18next.changeLanguage(lang);
} else {
    i18next.changeLanguage(config.supported_langs[0]);
    localStorage.setItem("language", config.supported_langs[0]);
}

export async function loadResources(lang) {
    try {
        const translations = await import(`./${lang}/${lang}.json`);
        i18next.addResourceBundle(lang, "translation", translations);
    } catch (error) {
        console.error("Error loading resources:", error);
    }
}

export default Translator;
