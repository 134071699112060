import "./AmsterdamLive.scss";
import { ScrollToTopOnPathChange } from "helpers/Description.helpers";
import ImageExtractor from "utils/Extractors/images";
import IconContainer from "components/Rooms/Description/IconContainer";
import { useTranslation } from "react-i18next";
import { playersImg, timeImg } from "helpers/Description.helpers";
import { getRoom } from "helpers/Description.helpers";
import config from "config/main-config.json";
import HomeCarousel from "components/HomeCarousel/HomeCarousel";
import { useState } from "react";

export const AmsterdamLive = () => {
    ScrollToTopOnPathChange();
    const room = window.location.pathname.split("/")[3];
    getRoom(room, config);
    const { t } = useTranslation();
    const [hoveredTab, setHoveredTab] = useState("week");

    const handleMouseEnter = (tab) => {
        setHoveredTab(tab);
    };

    const handleMouseLeave = () => {
        setHoveredTab(hoveredTab);
    };

    return (
        <>
            <HomeCarousel type='rooms' />
            <div
                className='col-lg-12 outdoor-box'
                id='description-box'>
                <div className='col-lg-10 text-center'>
                    <img
                        className='my-0 mx-auto mt-8'
                        alt='top secret logo'
                        loading='lazy'
                        src={ImageExtractor(
                            `default/pages/Room/top-secret-live.png`
                        )}
                    />
                    <a
                        href='https://www.youtube.com/watch?v=V2cEj6oJDIQ'
                        target='_blank'
                        rel='noreferrer'
                        className='inline-block my-[5rem] bg-[rgba(218,26,53,0.5)] border-2 border-[#da1a35] text-white rounded-[10px] px-2 py-1 uppercase font-black hover:bg-[rgba(218,26,53,0.8)]'>
                        Kijk trailer
                    </a>
                    <div className='flex space-between mb-[3rem] mdl:flex-col'>
                        <IconContainer
                            IconImg={playersImg}
                            alt='players'
                            paragraph={<>2 - 8</>}
                        />

                        <div className='text-center mdl:mt-[7rem] mdl:mb-[3rem]'>
                            <div>
                                <div className='price-days'>
                                    <svg
                                        id='day-week-live'
                                        data-name='Layer 10'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 893.57 213.68'
                                        onMouseEnter={() =>
                                            handleMouseEnter("week")
                                        }
                                        onMouseLeave={handleMouseLeave}
                                        className={
                                            hoveredTab === "week"
                                                ? "opacity-100"
                                                : "opacity-50"
                                        }>
                                        <polygon
                                            className='cls-2'
                                            points='499.69 161.19 394.71 161.19 447.2 213.68 499.69 161.19'></polygon>
                                        <path
                                            className='cls-3'
                                            d='M893.57,140.39c0,12.39-13.81,22.53-30.65,22.53H31.24C14.4,162.92.83,152.78.83,140.39V22.53C.83,10.14,14.64,0,31.24,0H862.92c16.84,0,30.65,10.14,30.65,22.53Z'></path>

                                        <text
                                            className='cls-4'
                                            transform='translate(31.92 108.75)'>
                                            MONDAY - THURSDAY
                                        </text>
                                    </svg>
                                    <svg
                                        id='day-weekend-live'
                                        data-name='Layer 10'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 893.57 213.68'
                                        onMouseEnter={() =>
                                            handleMouseEnter("weekend")
                                        }
                                        onMouseLeave={handleMouseLeave}
                                        className={
                                            hoveredTab === "weekend"
                                                ? "opacity-100"
                                                : "opacity-50"
                                        }>
                                        <polygon
                                            className='cls-2'
                                            points='499.27 163.1 394.29 163.1 446.78 215.59 499.27 163.1'></polygon>
                                        <path
                                            className='cls-3'
                                            d='M893.15,142.3c0,12.39-13.8,22.53-30.64,22.53H30.82C14,164.83.42,154.69.42,142.3V24.44c0-12.39,13.8-22.53,30.4-22.53H862.51c16.84,0,30.64,10.14,30.64,22.53Z'></path>

                                        <text
                                            className='cls-4'
                                            transform='translate(138.96 110.67)'>
                                            FRIDAY - SUNDAY
                                        </text>
                                    </svg>
                                </div>
                                <img
                                    className='my-0 mx-auto w-[150px]'
                                    alt='top secret logo'
                                    loading='lazy'
                                    src={ImageExtractor(
                                        `default/pages/Room/groups.svg`
                                    )}
                                />

                                <div className='price-price'>
                                    <svg
                                        id='price-week'
                                        data-name='Layer 10'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 716.2 213.68'
                                        style={{
                                            display: `block`,
                                        }}>
                                        <polygon
                                            className='cls-2'
                                            points='292.35 52.49 397.33 52.49 344.84 0 292.35 52.49'></polygon>
                                        <path
                                            className='cls-2'
                                            d='M691.64,50.76H397.33L303.84,213.68h387.8c13.32,0,24.4-10.14,24.4-22.53V73.29C716,60.9,705.15,50.76,691.64,50.76Z'></path>
                                        <path
                                            className='cls-3'
                                            d='M41.64,50.76H24.29C11.62,50.76,1.08,59.68-.18,71A21.48,21.48,0,0,0-.3,73.29V191.15A20.35,20.35,0,0,0-.1,194C1.42,205,11.82,213.68,24.29,213.68H303.84L397.33,50.76Z'></path>
                                        <g className='cls-3'>
                                            <text
                                                className='cls-4'
                                                transform='translate(418.71 165.38)'>
                                                TEAM
                                            </text>
                                        </g>
                                        <text
                                            className='cls-4'
                                            transform='translate(61.21 145.38)'>
                                            {hoveredTab === "week"
                                                ? "99"
                                                : "110"}
                                        </text>
                                        <text
                                            className='cls-4'
                                            transform='translate(191.87 156.42)'>
                                            €
                                        </text>
                                        <rect
                                            className='cls-7'
                                            x='-134.83'
                                            y='-48.56'
                                            width='139.12'
                                            height='420.26'></rect>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <IconContainer
                            IconImg={timeImg}
                            alt='time'
                            paragraph={<>60`</>}
                        />
                        <div className='hidden'>
                            <IconContainer />
                        </div>
                    </div>
                    <div className='row mdl:mt-[7rem]'>
                        <div className='w-full'>
                            <div className='w-full flex justify-between'>
                                <p className='difficulty-word text-uppercase mdl:ml-[5%]'>
                                    {t("Difficulty")}
                                </p>
                                <p className='difficulty-percentage mr-[3%] mdl:mr-[5%] text-uppercase'>
                                    95%
                                </p>
                            </div>
                        </div>
                        <div className='bg-white rounded-[5px] flex h-[2%] w-[95%] absolute my-[2rem] mx-auto mdl:h-[0.6rem] mdl:w-[90%] mdl:ml-[1%] text-center'>
                            <div
                                className='rounded-[5px] h-[100%] bg-primary-red'
                                role='progressbar'
                                style={{
                                    width: `95%`,
                                }}
                                aria-valuenow='95'
                                aria-valuemin='0'
                                aria-valuemax='100'></div>
                        </div>
                    </div>
                    <div className='mt-8'>
                        <p className='text-left'>
                            {t("amsterdam-top-secret-live-desc")}
                        </p>
                    </div>

                    <div className='amsterdam-faq'>
                        <div className='videoplaylogo'>
                            <img
                                className='my-0 mx-auto'
                                alt='video live logo'
                                loading='lazy'
                                src={ImageExtractor(
                                    `default/pages/Room/videoliveplaylogo.png`
                                )}
                            />
                        </div>
                        <div className='accordion-started accordion-bral row'>
                            <div className='col'>
                                <input
                                    className='ac-input'
                                    id='ac-1'
                                    name='accordion-1'
                                    type='radio'
                                />
                                <label
                                    className='ac-label'
                                    htmlFor='ac-1'>
                                    {t("amsterdam_live_q1")}
                                    <i></i>
                                </label>
                                <div className='article ac-content'>
                                    <p className='text-justify'>
                                        {t("amsterdam_live_a1")}
                                    </p>
                                </div>
                            </div>

                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                <input
                                    className='ac-input'
                                    id='ac-2'
                                    name='accordion-1'
                                    type='radio'
                                />
                                <label
                                    className='ac-label'
                                    htmlFor='ac-2'>
                                    {t("amsterdam_live_q2")}
                                    <i></i>
                                </label>
                                <div className='article ac-content'>
                                    <p className='text-justify'>
                                        {t("amsterdam_live_a2")}
                                    </p>
                                </div>
                            </div>

                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                <input
                                    className='ac-input'
                                    id='ac-3'
                                    name='accordion-1'
                                    type='radio'
                                />
                                <label
                                    className='ac-label'
                                    htmlFor='ac-3'>
                                    {t("amsterdam_live_q3")}
                                    <i></i>
                                </label>
                                <div className='article ac-content'>
                                    <p className='text-justify'>
                                        {t("amsterdam_live_a3")}
                                    </p>
                                </div>
                            </div>

                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                <input
                                    className='ac-input'
                                    id='ac-4'
                                    name='accordion-1'
                                    type='radio'
                                />
                                <label
                                    className='ac-label'
                                    htmlFor='ac-4'>
                                    {t("amsterdam_live_q4")}
                                    <i></i>
                                </label>
                                <div className='article ac-content'>
                                    <p className='text-justify'>
                                        {t("amsterdam_live_a4")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className='content-section text-center p-4'>
                        <a
                            href='tel:+31 20 2101 911'
                            className='btn btn-default btn-lg'>
                            To Book Call : +31 20 2101 911
                        </a>
                    </section>
                </div>
            </div>
        </>
    );
};
export default AmsterdamLive;
