import AmsterdamLive from "components/Rooms/AmsterdamLive/AmsterdamLive";

export const AmsterdamTopSecretLive = () => {
    return (
        <>
            <AmsterdamLive />
        </>
    );
};

export default AmsterdamTopSecretLive;
