import { iframeResize } from "@iframe-resizer/child";

export const useScript = (url, ref) => {
    const existingScript = document.getElementById("bookeo-script");
    if (existingScript) return;

    const script = document.createElement("script");
    script.id = "bookeo-script";
    script.src = url;
    script.defer = true;
    script.type = "text/javascript";
    ref.current.appendChild(script);

    return () => {
        ref.current.removeChild(script);
    };
};

export const useResovaVoucherScript = (url, ref, store) => {
    const existingScript = document.getElementById("resova-iframe");
    if (existingScript) return;

    const iframe = document.createElement("iframe");
    iframe.id = "resova-iframe";
    iframe.className = `resova_voucher_iframe_${store}`;
    iframe.src = url;
    iframe.async = true;
    iframe.scrolling = "no";

    ref.current.appendChild(iframe);

    return () => {
        ref.current.removeChild(iframe);
    };
};

export const useForEscapeScript = (url, ref, store, type) => {
    const existingScript = document.getElementById("forescape");
    if (existingScript) return;

    const iframe = document.createElement("iframe");
    iframe.id = "forescape";
    iframe.className =
        type === "4escape_vouchers"
            ? `forescape_iframe_voucher_${store}`
            : `forescape_iframe_${store}`;
    iframe.src = url;
    iframe.async = true;
    iframe.scrolling = "no";
    iframeResize({ checkOrigin: false }, iframe);

    ref.current.appendChild(iframe);

    return () => {
        ref.current.removeChild(iframe);
    };
};

export const scriptMapper = (type, store) => {
    const scriptMap = {
        booking: store.booking_widgets_src,
        gift_vouchers: store.gift_vouchers_widgets_src,
        redeem_vouchers: store.redeem_vouchers_widgets_src,
        resova_vouchers: store.resova_voucher_widgets_src,
        "4escape_booking": store.for_escape_booking,
        "4escape_vouchers": store.for_escape_vouchers,
    };
    if (!scriptMap[type])
        console.warn("Unknown Script type detected see scriptMapper ");

    return scriptMap[type] || null;
};

export const bookeoSectionClass = (storeName) => {
    return `bookeo_section_of_${storeName}`;
};

export const getLiverpoolIframeProps = (isLiverpoolBooking) => {
    return isLiverpoolBooking
        ? {
              allow: "payment",
              name: "checkfront",
          }
        : {};
};
