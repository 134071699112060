const ImageExtractor = (srcFile) => {
    try {
        srcFile = require(`assets/${srcFile}`);
        return srcFile;
    } catch (error) {
        console.log(
            `Extraction unsuccessful for ${srcFile} asset : : : Error > ${error}`
        );
    }
    return srcFile;
};

export default ImageExtractor;
