import { Careers } from "components/Careers/Careers";

const CareersPage = () => {
    return (
        <>
            <Careers />
        </>
    );
};

export default CareersPage;
