import config from "config/main-config.json";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { getClass } from "helpers/OpeningHours";
import { testIds } from "enums/testIds";

const OpeningHours = ({ type, isFindUs }) => {
    const { opening_hours, find_us } = config.runtime.pages.components;
    const { t } = useTranslation();

    return (
        <div>
            {!isFindUs && (
                <div
                    data-testid={testIds.opening_hours}
                    className={getClass(type)}
                    dangerouslySetInnerHTML={{
                        __html: t(opening_hours),
                    }}></div>
            )}
            {isFindUs && (
                <div
                    data-testid={testIds.find_us}
                    className={`${type}_find_us text-center text-white`}
                    dangerouslySetInnerHTML={{ __html: t(find_us) }}></div>
            )}
        </div>
    );
};

OpeningHours.propTypes = {
    type: PropTypes.string,
    isFindUs: PropTypes.bool,
};

export default OpeningHours;
