import "./ScrollDown.scss";
import { useScrollPosition } from "helpers/general.helpers";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

const ScrollDown = () => {
    const { scrollPosition } = useScrollPosition();
    const { t } = useTranslation();

    return (
        scrollPosition < 100 && (
            <div
                className={twMerge(
                    "left-[50%] absolute text-center bottom-0 translate-x-[-50%]",
                    "translate-y-[-50%] flex justify-center md:bottom-[10%]"
                )}>
                {[1, 2, 3].map((_, index) => (
                    <div
                        key={index}
                        className={twMerge(
                            "chevron absolute w-[28px] h-[8px] opacity-0",
                            "[transform:scale3d(0.5,0.5,0.5)] [animation:move_3s_ease-out_infinite]"
                        )}></div>
                ))}
                <span
                    className={twMerge(
                        "flex mt-[75px] [font-family:'Helvetica Neue','Helvetica',Arial,sans-serif]",
                        "text-[12px] text-white uppercase flex-nowrap opacity-[0.25]"
                    )}>
                    {t("Scroll Down")}
                </span>
            </div>
        )
    );
};

export default ScrollDown;
