import { Navigate } from "react-router-dom";
import { useEffect } from "react";

const NavigateToHomePage = () => {
    useEffect(() => {
        window.location.reload();
    }, []);
    return <Navigate to='/' />;
};

export default NavigateToHomePage;
