import config from "config/main-config.json";

const partyTownType = "text/partytown";

export const setGoogleAnalytics = () => {
    const head = document.getElementsByTagName("head")[0];
    if (config.google_tags?.snapchat_icon_script) {
        const snapChatScript = document.createElement("script");
        snapChatScript.defer = true;
        snapChatScript.async = true;
        snapChatScript.type = partyTownType;
        snapChatScript.crossOrigin = "anonymous";
        snapChatScript.src = config.google_tags?.snapchat_icon_script;
        head.appendChild(snapChatScript);
    }

    config.google_tags?.google_analytics.forEach((item) => {
        if (item.src) {
            const scriptSrc = document.createElement("script");
            scriptSrc.defer = true;
            scriptSrc.async = true;
            scriptSrc.type = partyTownType;
            scriptSrc.src = item.src;
            head.appendChild(scriptSrc);
        }

        if (item.script) {
            const scriptInnerText = document.createElement("script");
            scriptInnerText.defer = true;
            scriptInnerText.async = true;
            scriptInnerText.type = partyTownType;
            scriptInnerText.innerText = item.script;
            head.appendChild(scriptInnerText);
        }
    });
};
