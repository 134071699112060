import RomeOutdoor from "components/Rooms/RomeOutdoor/RomeOutdoor";

export const OutdoorActivity = () => {
    return (
        <>
            <RomeOutdoor />
        </>
    );
};

export default OutdoorActivity;
