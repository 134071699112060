//custom Nantes toggle to show operating hours
//in home carousel and inside rooms
import { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useOutsideClick } from "helpers/general.helpers";
import "./CustomModal.scss";
import { testIds } from "enums/testIds";

const CustomModal = ({ data, classNames, modalClass = "" }) => {
    const modalRef = useRef(null);
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const handleToggle = () => setShowModal(!showModal);

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
        }
    };

    useOutsideClick(handleClickOutside);

    return (
        <>
            <div
                ref={modalRef}
                className={classNames}
                onClick={handleToggle}
                data-testid={testIds.toggle_div}>
                {t(data.text)} {showModal ? "v" : ">"}
            </div>
            {showModal && (
                <div
                    className={`max-w-[320px] m-[auto] absolute left-0 right-0 bottom-[20%] mdl:bottom-[25%] p-[2rem] bg-black bg-opacity-70 rounded-[30px] ${modalClass}`}
                    data-testid={testIds.modal}>
                    <div>
                        <div className='flex items-center justify-between h-[100%] ![margin: 0.3rem_auto] text-white text-[18px]'>
                            <p className='!text-[18px] leading-[1.2] m-[unset] font-Raleway !tracking-[-1px]'>
                                {t("Monday - Thursday")}
                            </p>
                            <p className='!text-[18px] leading-[1.2] m-[unset] font-Raleway !tracking-[-1px]'>
                                14:00 - 23:00
                            </p>
                        </div>
                        <div className='flex items-center justify-between h-[100%] ![margin: 0.3rem_auto] text-white text-[18px]'>
                            <p className='!text-[18px] leading-[1.2] m-[unset] font-Raleway !tracking-[-1px]'>
                                {t("Friday")}
                            </p>
                            <p className='!text-[18px] leading-[1.2] m-[unset] font-Raleway !tracking-[-1px]'>
                                14:00 - 24:00
                            </p>
                        </div>
                        <div className='flex items-center justify-between h-[100%] ![margin: 0.3rem_auto] text-white text-[18px]'>
                            <p className='!text-[18px] leading-[1.2] m-[unset] font-Raleway !tracking-[-1px]'>
                                {t("Saturday")}
                            </p>
                            <p className='!text-[18px] leading-[1.2] m-[unset] font-Raleway !tracking-[-1px]'>
                                10:00 - 24:00
                            </p>
                        </div>
                        <div className='flex items-center justify-between h-[100%] ![margin: 0.3rem_auto] text-white text-[18px]'>
                            <p className='!text-[18px] leading-[1.2] m-[unset] font-Raleway !tracking-[-1px]'>
                                {t("Sunday")}
                            </p>
                            <p className='!text-[18px] leading-[1.2] m-[unset] font-Raleway !tracking-[-1px]'>
                                10:00 - 20:00
                            </p>
                        </div>
                        <span className='font-Raleway !text-center text-primary-red font-black'>
                            {t("during_school_holidays")}
                        </span>
                        <div className='flex items-center justify-between h-[100%] ![margin: 0.3rem_auto] text-white text-[18px]'>
                            <p className='!text-[18px] leading-[1.2] m-[unset] !font-Raleway !tracking-[-1px]'>
                                {t("Monday - Friday")}
                            </p>
                            <p className='!text-[18px] leading-[1.2] m-[unset] !font-Raleway !tracking-[-1px]'>
                                10:00 - 24:00
                            </p>
                        </div>
                        <div className='flex items-center justify-between h-[100%] ![margin: 0.3rem_auto] text-white text-[18px]'>
                            <p className='!text-[18px] leading-[1.2] m-[unset] !font-Raleway !tracking-[-1px]'>
                                {t("Saturday - Sunday")}
                            </p>
                            <p className='!text-[18px] leading-[1.2] m-[unset] !font-Raleway !tracking-[-1px]'>
                                14:00 - 24:00
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

CustomModal.propTypes = {
    data: PropTypes.string,
    classNames: PropTypes.any,
    modalClass: PropTypes.any,
};

export default CustomModal;
