import { twMerge } from "tailwind-merge";

export const getClass = (type) => {
    switch (type) {
        case "carousel":
            return twMerge(
                "flex-col whitespace-pre-line items-center translate-y-[70%]",
                "[&>div]:inline-flex [&>div]:w-[25%] [&>div]:justify-center",
                "[&>div]:h-[1.6rem] [&>div>.p-left]:flex [&>div>.p-left]:w-[50%]",
                "[&>div>.p-right]:flex [&>div:last-of-type]:text-[0.8rem] mdl:[&>div]:w-[70%]",
                "mdl:[&>div]:h-[2rem] lg:[&>div]:w-[50%] lg:[&>div]:h-[1.5rem]",
                "[&>div>p]:text-[26px] sm:[&>div:first-of-type]:mt-[5%]",
                "md:[&>div:first-of-type]:mt-[15%] lg:[&>div:first-of-type]:mt-[15%]"
            );
        case "rooms":
            return twMerge(
                "flex flex-col whitespace-pre-line [margin:2rem_auto] mdl:ml-auto w-[100%]",
                "justify-center [&>div]:flex [&>div]:my-[-3%]",
                "[&>div]:text-white [&>div]:justify-between [&>div>.p-left]:w-[71%]",
                "mdl:[&>div>.p-left]:w-[50%] [&>div:last-of-type]:ml-auto",
                "mdl:[&>div]:justify-center mdl:[&>div]:my-[0.4%]",
                "mdl:[&>div>p]:w-[50%] mdl:[&>div:last-of-type]:ml-[unset]"
            );
        case "contact":
            return twMerge(
                "flex flex-col whitespace-pre-line [&>div]:flex [&>div]:justify-center",
                "[&>div>p]:w-[15%] [&>div>p]:mb-[0.5%] mdl:[&>div>p]:w-[50%] mdl:[&>div>p]:mb-[7%]"
            );
        default:
            return "";
    }
};
