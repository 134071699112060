import PropTypes from "prop-types";
import { Navigation, Pagination, EffectFade, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "./HomeCarousel.css";
import "./liveTheUltimate.css";
import "./canYouEscape.css";
import CarouselItem from "./CarouselCaptions";
import styles from "./styles.module.css";
import { useLocation } from "react-router-dom";
import {
    locationToImages,
    homeCarouselData,
} from "components/Rooms/Carousels/RoomCarousels";
import {
    getImageAttributes,
    getSwiperClassName,
    hasCaptions,
} from "helpers/HomeCarousel.helpers";
import { useState, useEffect, useRef } from "react";
import { useScreenWidth } from "helpers/general.helpers";
import ScrollDown from "components/Rooms/ScrollDown/ScrollDown";

/**
 * @name HomeCarousel
 * @returns the home carousel with the images and the settings
 */

const HomeCarousel = ({ type }) => {
    const location = useLocation();
    const threeHash = `/rooms/${location.pathname.split("/")[3]}`;
    const getLocationToImages = () => {
        if (location.pathname.includes("live")) {
            return threeHash;
        } else {
            return location.pathname;
        }
    };

    const locPath = getLocationToImages();
    const enrichedContent = locationToImages[locPath] || [];
    const isPortrait = window.orientation === 0 || window.orientation === 180;
    const swiperRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const { screenWidth } = useScreenWidth();

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.autoplay.start();
        }
    }, [swiperRef]);

    const handleSlideChange = (swiper) => {
        setCurrentSlide(swiper.realIndex);
    };

    const { className, id } = getImageAttributes(isPortrait, type);
    const carouselData =
        location.pathname === "/" ? homeCarouselData : enrichedContent;

    const swiperClassName = getSwiperClassName(
        type,
        styles,
        carouselData,
        currentSlide
    );

    return (
        <Swiper
            aria-label='home banner'
            id='homeBanner'
            ref={swiperRef}
            className={swiperClassName}
            modules={[Navigation, Pagination, EffectFade, Autoplay]}
            navigation
            loop
            autoplay={{ delay: 6000 }}
            effect='fade'
            fadeEffect={{ crossFade: true }}
            onSwiper={(swiper) => {
                swiper.autoplay.start();
                swiper.on("slideChange", () => handleSlideChange(swiper));
            }}>
            {carouselData.map((item, index) => (
                <SwiperSlide
                    key={`slide-${index}`}
                    className='swiper-item'>
                    {item.url && (
                        <a
                            className='[display:inherit] w-[inherit] h-[inherit] absolute z-[999] pt-[15%]'
                            href={item.url}></a>
                    )}
                    <img
                        src={item.image}
                        className={className}
                        id={id}
                        alt={`Game Over Escape Rooms Carousel Images ${index}`}
                        loading='lazy'
                    />
                    {type !== "rooms" && hasCaptions(item) ? (
                        <CarouselItem
                            key={`caption-${index}`}
                            item={item}
                        />
                    ) : null}
                    {type !== "rooms" && (
                        <div className='w-[100%] bottom-[0] h-[200px] absolute z-[2] [background:linear-gradient(to_bottom,transparent_40%,#0e0e0e_100%)]'></div>
                    )}
                    {type === "rooms" && screenWidth > 992 && <ScrollDown />}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

HomeCarousel.propTypes = {
    type: PropTypes.string,
    setOpenButtonModal: PropTypes.func,
};

export default HomeCarousel;
