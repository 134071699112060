import { useEffect, useState } from "react";

export const useShouldShow = () => {
    const [shouldShow, setShouldShow] = useState(false);

    useEffect(() => {
        if (window.location.pathname === "/") {
            const handleUserInteraction = () => setShouldShow(true);

            const events = [
                "keypress",
                "scroll",
                "click",
                "touchstart",
                "wheel",
                "mousemove",
            ];

            events.forEach((event) =>
                window.addEventListener(event, handleUserInteraction)
            );

            return () => {
                events.forEach((event) =>
                    window.removeEventListener(event, handleUserInteraction)
                );
            };
        }
    }, []);

    return { shouldShow };
};
