import { useEffect, useState } from "react";

export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () =>
            setScrollPosition(document.documentElement.scrollTop);

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return { scrollPosition };
};

export const useScreenWidth = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return { screenWidth };
};

export const useOutsideClick = (handleClickOutside) => {
    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);
};

export const useOpeningHoursClick = (func, condition) => {
    useEffect(() => {
        document.addEventListener("mousedown", func);
        return () => {
            document.removeEventListener("mousedown", func);
        };
    }, [condition]);
};
